@mixin lips($num) {
  @for $i from 1 through $num {
    &:nth-child(n + #{$i + 1}) {
      margin-left: #{$i * 31px};
    }
  }
}

@keyframes bobble {
  0%,
  100% {
    margin-top: 40px;
    margin-bottom: 48px;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.24);
  }
  50% {
    margin-top: 54px;
    margin-bottom: 34px;
    box-shadow: 0 24px 64px rgba(0, 0, 0, 0.4);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .browser {
    width: 280px;
    margin-left: -140px;
    .eye {
      left: 30%;
      + .eye {
        left: auto;
        right: 30%;
      }
    }
  }
}

.container-error {
  display: grid !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 0px;
  text-align: center;

  .error-message {
    font-size: 24px;
    line-height: 40px;
    margin: 0 auto 16px;
    padding: 0 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
    font-weight: 600;
    max-width: 600px;
  }

  .browser {
    width: 400px;
    min-width: 200px;
    min-height: 264px;
    background: #fff;
    box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    animation: bobble 1.8s ease-in-out infinite;
    position: relative;
    left: 50%;
    margin-left: -200px;

    .controls {
      width: 100%;
      height: 32px;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 3px 3px 0 0;
      box-sizing: border-box;
      padding: 10px 12px;
      i {
        height: 12px;
        width: 12px;
        border-radius: 100%;
        display: block;
        float: left;
        background: rgba(0, 0, 0, 0.25);
        margin-right: 8px;
      }
    }

    .eye {
      position: absolute;
      left: 34%;
      top: 80px;
      width: 32px;
      height: 32px;
      opacity: 1;
      + .eye {
        right: 34%;
        left: auto;
      }
      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 40px;
        width: 3px;
        border-radius: 2px;
        background-color: #ff5e5b;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }

    .mouth {
      position: absolute;
      width: 250px;
      top: 178px;
      left: 50%;
      margin-left: -125px;
      height: 40px;
      .lips {
        position: absolute;
        left: 15px;
        content: " ";
        height: 40px;
        width: 3px;
        border-radius: 2px;
        background-color: #ff5e5b;
        transform: rotate(-54deg);
        &:nth-child(odd) {
          transform: rotate(54deg);
        }
        @include lips(8);
      }
    }
  }

  div {
    div {
      h6 {
        .error-support-link {
          font-weight: 900;
          cursor: pointer;
        }
      }
    }
    
  }
}
