.not-found-link {
  color: #001e27;
  display: block;
  cursor: pointer;
  -webkit-animation: pulseColor 1000ms linear 3100ms forwards;
  animation: pulseColor 3s linear 1s infinite alternate;
  font-weight: 500;
}

.not-found-link:hover, .not-found-link:focus, .not-found-link:active {
  color: #00E2F4!important;
}

.not-found-svg {
  width: 50px;
  height: auto;
}

.crack {
  position: relative;
  z-index: 4;
}

.crack polyline {
	height: 300px;
  fill: none;
  stroke: #001e27;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1649.099;
  stroke-dashoffset: 1649.099;
  -webkit-animation: drawStroke 1500ms ease-out 500ms forwards;
  animation: drawStroke 1500ms ease-out 500ms forwards;
}

.not-found {
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
  height: 100% !important;
  padding: 0px;
}

.not-found > div {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  overflow: hidden;
}

.not-found > div .not-found-svg {
  position: relative;
  z-index: 1;
}

.not-found > div .not-found-svg polygon {
  fill: rgba(52, 52, 52, 0);
}

.not-found > div:first-child {
  text-align: right;
  z-index: 1;
  padding-right: 30px;
}

.not-found > div:first-child span:first-child {
  opacity: 0;
  transform: translateX(100%);
  -webkit-animation: translateLeft 1000ms linear 1250ms forwards;
  animation: translateLeft 1000ms linear 1250ms forwards;
}

.not-found > div:first-child span:last-child {
  opacity: 0;
  transform: translateX(100%);
  -webkit-animation: translateLeft 1000ms linear 1450ms forwards;
  animation: translateLeft 1000ms linear 1450ms forwards;
}

.not-found > div:first-child .not-found-svg polygon {
  -webkit-animation: removeFill 10ms ease-out 1600ms forwards;
  animation: removeFill 10ms ease-out 1600ms forwards;
}

.not-found > div:last-child {
  z-index: 0;
  padding-left: 30px;
}

.not-found > div:last-child span:first-child {
  opacity: 0;
  transform: translateX(-100%);
  -webkit-animation: translateRight 1000ms linear 1650ms forwards;
  animation: translateRight 1000ms linear 1650ms forwards;
}

.not-found > div:last-child span:last-child {
  opacity: 0;
  transform: translateX(-100%);
  -webkit-animation: translateRight 1000ms linear 1850ms forwards;
  animation: translateRight 1000ms linear 1850ms forwards;
}

@-webkit-keyframes drawStroke {
  0% {
    stroke-dashoffset: 1649.099;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawStroke {
  0% {
    stroke-dashoffset: 1649.099;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes removeFill {
  0% {
    fill: #343434;
  }
  100% {
    fill: rgba(52, 52, 52, 0);
  }
}
@keyframes removeFill {
  0% {
    fill: #343434;
  }
  100% {
    fill: rgba(52, 52, 52, 0);
  }
}

@-webkit-keyframes pulseColor {
  0% {
    color: #001e27;
  }
  25% {
    color: #d8fe00;
  }
  50% {
    color: #001e27;
  }
  75% {
    color: #d8fe00;
  }
  100% {
    color: #001e27;
  }
}

@keyframes pulseColor {
  0% {
    color: #001e27;
  }
  25% {
    color: #d8fe00;
  }
  50% {
    color: #001e27;
  }
  75% {
    color: #d8fe00;
  }
  100% {
    color: #001e27;
  }
}

@-webkit-keyframes translateLeft {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

@keyframes translateLeft {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

@-webkit-keyframes translateRight {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}

@keyframes translateRight {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -150, 0, 0, 1);
    opacity: 1;
  }
  7.61% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -69.561, 0, 0, 1);
  }
  11.41% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -39.355, 0, 0, 1);
  }
  15.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -17.801, 0, 0, 1);
  }
  18.92% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.02, 0, 0, 1);
  }
  22.72% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 5.661, 0, 0, 1);
  }
  30.23% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 10.852, 0, 0, 1);
  }
  50.25% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 2.282, 0, 0, 1);
  }
  70.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.519, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
